import React from 'react';
import FeatureCardsSection from './featureCardsSection';
import KompleksowaUsluga from '../../assets/svg/iqcode-technologie-stack.svg';
import CzasRealizacji from '../../assets/svg/metodyki-zwinne-agile.svg';
import Gwarancja from '../../assets/svg/gwarancje-wsparcie-sla.svg';

const DlaczegoWarto = () => (
  <FeatureCardsSection
    title={(
      <>
        Why
        {' '}
        <span>iqcode</span>
        ?
      </>
              )}
    subtitle="What makes our services stand out"
    cards={[
      {
        title: (
          <>
            Modern
            <br />
            Tech Stack
          </>
        ),
        icon: <KompleksowaUsluga />,
        link: 'Our technologies',
        to: '/tech-stack/',
        desc:
                        'PHP and jQuery? We don\'t do that here. Our modern tech stack allows us to minimize technical debt',
      },
      {
        title: (
          <>
            Agile
            <br />
            Process
          </>
        ),
        icon: <CzasRealizacji />,
        link: 'Let\'s discuss your project',
        to: '/contact/',
        desc:
                        'We develop digital products iteratively, with active participation of the stakeholders',
      },
      {
        title: (
          <>
            Warranties
            <br />
            and SLAs
          </>
        ),
        icon: <Gwarancja />,
        link: 'DevOps at iqcode',
        to: '/devops/',
        desc:
                        'We provide written warranties and restrictive SLAs enabled by our DevOps practices',
      },
    ]}
  />
);

export default DlaczegoWarto;
