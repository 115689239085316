import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Grid } from '@material-ui/core';
import LogoMain from '../../assets/svg/logo-full-alt-big.svg';
import DlaczegoWarto from '../components/dlaczegoWarto';
import FeatureSectionDark from '../components/featureSectionDark';
import ReactIcon from '../../assets/svg/react-original.svg';
import FlutterIcon from '../../assets/svg/flutter-original.svg';
import GatsbyIcon from '../../assets/svg/gatsby-plain.svg';
import WordPressIcon from '../../assets/svg/wordpress-plain.svg';
import WorkBoxIcon from '../../assets/svg/workbox.svg';
import NodeIcon from '../../assets/svg/nodejs-plain.svg';
import PythonIcon from '../../assets/svg/python-plain.svg';
import BackendIcon from '../../assets/svg/aplikacje-web.svg';
import MobileIcon from '../../assets/svg/aplikacje-mobilne.svg';
import WebsitesIcon from '../../assets/svg/strony-internetowe.svg';
import PWAIcon from '../../assets/svg/publikacja-strony-internetowej.svg';

import FeatureSectionLight from '../components/featureSectionLight';
import TechBox from '../components/techBox';
import AllServices from '../components/allServices';
import SEO from '../components/seo';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
        query Images {
            image: file(
                relativePath: { eq: "nowoczesny-design-strony-internetowe.jpg" }
            ) {
                id
                childImageSharp {
                    fixed {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            pwa: file(relativePath: { eq: "aplikacje-progresywne-pwa.png" }) {
                id
                childImageSharp {
                    fixed {
                        ...GatsbyImageSharpFixed
                    }
                    fluid(quality: 95, traceSVG: { threshold: 50 }) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            aplikacje: file(
                relativePath: { eq: "iqcode-aplikacje-mobilne-dark.png" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 50 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            backend: file(relativePath: { eq: "aplikacje-serwerowe-l.png" }) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 50 }) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            erp: file(relativePath: { eq: "systemy-erp.png" }) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 50 }) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            kompleksowa: file(
                relativePath: { eq: "programowanie-praca-zespolowa.jpg" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            sh: file(relativePath: { eq: "iqcode-software-house-l.png" }) {
                id
                childImageSharp {
                    fluid(quality: 95) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            shppl: file(relativePath: { eq: "nextcloud-bg.jpg" }) {
                id
                childImageSharp {
                    fluid(maxWidth: 2560, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            kompleksowaus: file(
                relativePath: { eq: "kompleksowa-usluga.png" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
  return (
    <>
      <SEO title="iqcode - Software House based in Warsaw, Poland" description="iqcode is a software development company which specializes in Progressive Web Applications (PWA), backend development and mobile development." />
      <div
        className="section scrollspy no-pad-bot hero overlay-holder"
        id="agencja-interaktywna"
      >
        <div className="diagonal-overlay" id="do2" />
        <div className="diagonal-overlay" />
        <Img
          fluid={data.shppl.childImageSharp.fluid}
          imgStyle={{
            objectFit: 'cover',
            zIndex: -1,
          }}
          style={{
            height: '100%',
            position: 'absolute',
            width: '100%',
          }}
        />
        <Container maxWidth="xl" className="fh">
          <Grid container alignItems="center" className="fh">
            <Grid className="z-1" item md={12} lg={7}>
              <div>
                <h1
                  className="intro1"
                >
                  We Design, Develop and Ship
                  <br />
                  <span>Software Products</span>
                  <br />
                  {' '}
                  for Business
                </h1>
                <a
                  className="btn btn-alt"
                  href="#o-nas"
                  style={{
                    color: 'white',
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                  onClick={() => {
                    document
                      .querySelector('#stm')
                      .scrollIntoView({
                        behavior: 'smooth',
                      });
                  }}
                >
                  Take a tour
                </a>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div id="stm">
        <Container className="mwr">
          <Grid container>
            <Grid item sm={12} md={8}>
              <h2>
                Modern
                {' '}
                <span>Software House</span>
              </h2>
              <p>
                <strong>iqcode</strong>
                {' '}
                is a Software House founded in 2018. Since then
                we have completed numerous software projects for clients
                ranging from small businesses all the way up to SaaS tech startups.
              </p>
              <p>
                We develop modern and scalable digital products
                without introducing unnecessary technical debt.
                Our main areas of expertise are: Progressive Web Apps (PWAs),
                backend applications in microservices architecture written
                in Node.js and Python and DevOps solutions implemented in Kubernetes.
              </p>
            </Grid>
            <Grid item sm={12} md={4} style={{ padding: '30px' }}>
              <LogoMain className="inline-svg-fw" />
            </Grid>
          </Grid>
          <TechBox
            fullWidth
            technologies={[
              {
                icon: <MobileIcon />,
                name: 'Mobile Apps',
                link: '/mobile-apps/',
              },
              {
                icon: <WebsitesIcon />,
                name: 'Web Development',
                link: '/web-development/',
              },
              {
                icon: <PWAIcon />,
                name: 'Progressive Web Apps',
                link: '/progressive-web-apps/',
              },
              {
                icon: <BackendIcon />,
                name: 'Backend Development',
                link: '/backend-development/',
              },
            ]}
          />
        </Container>
      </div>
      <div className="promo overlay-holder z-depth-3">
        <div className="diagonal-overlay-light" />
        <div className="rel">
          <h3>
            Considering starting a
            {' '}
            <span>software project</span>
            ?
          </h3>
          <Link className="btn" to="/contact/">
            Let's talk!
          </Link>
        </div>
      </div>
      <DlaczegoWarto />
      <FeatureSectionDark
        title={(
          <>
            Mobile
            {' '}
            <span>Apps</span>
          </>
                  )}
        subtitle="Engaging and fast"
        img={(
          <Img
            alt="modern mobile apps development"
            fluid={data.aplikacje.childImageSharp.fluid}
            backgroundColor="#252b35"
          />
                )}
        to="/mobile-apps/"
        link="Learn more"
        technologies={[
          { icon: <ReactIcon />, name: 'React Native' },
          { icon: <FlutterIcon />, name: 'Flutter' },
        ]}
      />
      <FeatureSectionLight
        title={(
          <>
            Web
            {' '}
            <span>Development</span>
          </>
                  )}
        subtitle="With a modern tech-stack"
        img={(
          <Img
            alt="modern web development"
            fluid={data.image.childImageSharp.fluid}
          />
                )}
        to="/web-development/"
        link="Learn more"
        technologies={[
          { icon: <GatsbyIcon />, name: 'Gatsby.js' },
          {
            icon: <WordPressIcon />,
            name: <>
              Word
              <wbr />
              Press
                  </>,
          },
        ]}
      />
      <FeatureSectionDark
        title={(
          <>
            <span>Progressive</span>
            <br />
            Web Apps
          </>
                  )}
        subtitle="A modern alternative to classic mobile apps"
        img={(
          <Img
            alt="progressive web apps"
            fluid={data.pwa.childImageSharp.fluid}
          />
                )}
        to="/progressive-web-apps/"
        link="Learn more"
        technologies={[
          { icon: <ReactIcon />, name: 'React.js' },
          { icon: <WorkBoxIcon />, name: 'Workbox' },
        ]}
      />
      <FeatureSectionLight
        title={(
          <>
            <span>Backend</span>
            {' '}
            Development
          </>
                  )}
        subtitle="Scalable server-side applications"
        img={(
          <Img
            alt="scalable server-side software development"
            fluid={data.backend.childImageSharp.fluid}
          />
                )}
        to="/backend-development/"
        link="Learn more"
        technologies={[
          { icon: <NodeIcon />, name: 'Node.js' },
          { icon: <PythonIcon />, name: 'Python' },
        ]}
      />
      <FeatureSectionLight
        title={(
          <>
            <span>All-rounded </span>
            IT project delivery
          </>
                  )}
        mb0
        img={(
          <div style={{ padding: '10%' }}>
            <Img
              alt="complex software development services"
              fluid={data.kompleksowaus.childImageSharp.fluid}
              imgStyle={{ width: 'auto' }}
            />
          </div>
                  )}
        desc={(
          <>
            <p>
              We deliver complex IT projects starting at the requirements engineering stage, through development works following
              {' '}
              <strong>agile</strong>
              {' '}
              principles and project consulting regarding the app's public start finishing off with rock-solid tech support, data-driven analytics and iterative evolution of the digital product.
            </p>
            <p>
              Our wide variety of mobile, web and server-side technologies allows us to deliver complex IT projects cross-cutting numerous software engineering areas devoid of technical debt.
            </p>
          </>
                  )}
        link="Get in touch"
        to="/contact"
      />
      <AllServices />
    </>
  );
};

export default IndexPage;
